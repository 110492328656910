/*
 * @Author: jiaaolei 1137400804@qq.com
 * @Date: 2024-09-03 23:07:19
 * @LastEditors: jiaaolei 1137400804@qq.com
 * @LastEditTime: 2024-09-05 23:49:33
 * @FilePath: \jin_dong\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; 
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
const createjs = window.createjs;
// 全局挂载
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);


Vue.config.productionTip = false
Vue.prototype.createjs = createjs;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
