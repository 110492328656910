<!--
 * @Author: jiaaolei 1137400804@qq.com
 * @Date: 2024-09-03 23:07:19
 * @LastEditors: jiaaolei 1137400804@qq.com
 * @LastEditTime: 2024-09-11 22:53:20
 * @FilePath: \jin_dong\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div :class="mode=='landscape'?'home-container-landscape':'home-container-portrait'">
		<img :class="mode=='landscape'?'image-landscape':'image-portrait'"  src="../assets/img/splash.jpg" @click="gotoMain">
	</div>
</template>

<script>

export default {
	name: 'Home',
	components: {
	},
	data(){
		return {
			mode: 'landscape'
		}
	},
	mounted(){
		let width = window.innerWidth
		let height = window.innerHeight
		if (width < height) {
			this.mode = 'landscape'
		} else {
			this.mode = 'portrait'
		}
	},
	methods:{
		pushRoute(path){
			this.$router.push({
				path
			})
		},
		gotoMain() {
			this.$router.push({
				path: '/main'
			})
		}
	}
}
</script>
<style scoped>
	.home-container-portrait{
		display: flex;
		justify-content: center; 
		align-items: center;
		box-sizing: border-box;
		width: 100%;
        height: 100%;
		overflow: hidden;
    }
	.image-portrait {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.home-container-landscape{
		display: flex;
		justify-content: center; 
		align-items: center;
		box-sizing: border-box;
		width: 100%;
        height: 100%;
		overflow: hidden;
    }
	.image-landscape {
		object-fit: cover;
		width: 100vh;
        height: 100vw;
		transform-origin: center;
		transform: rotate(90deg);
	}
  
</style>