<!--
 * @Author: jiaaolei 1137400804@qq.com
 * @Date: 2024-09-03 22:45:11
 * @LastEditors: jiaaolei 1137400804@qq.com
 * @LastEditTime: 2024-09-11 23:19:24
 * @FilePath: \jin_dong\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components:{

  },
  computed:{
    tabColor(){
      return this.$route
    }
  },
  watch:{
    
    
  },
  mounted(){
    
    
  },
  data(){
    return{
      
    }
  },
  methods:{
    
  }
}
</script>
<style>
  
</style>
<style lang="less">
@import './assets/static/globle.less';
body,html{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: auto;
  
}

</style>
